<template>
  <div
    ref="provider"
    class="TextInput font-display"
    :class="{
      'mt-6': label,
    }"
    tag="div"
    :name="name"
    :vid="id"
  >
    <input
      v-if="type !== 'textarea'"
      :id="id"
      v-model="fieldValue"
      :name="name"
      class="w-full h-10 leading-normal bg-transparent"
      :class="{
        'bg-transparent placeholder-primary-2-04': filled && !isError,
        'bg-red-50': filled && isError,
        'placeholder-black': !filled && !isError,
        'border border-red-700': border && isError,
        'border border-primary-2-03 rounded-xs': border && !isError,
        'border-r border-primary-2-03': borderRight && !isError,
        'rounded-md': rounded,
        'has-value': hasValue,
        'border-b': underline,
        'pl-12': isError || $slots.icon,
        'px-4': !$slots.icon,
        'with-border': fullBorder,
      }"
      :type="showPassword ? 'text' : type"
      :placeholder="placeholder"
      v-bind="$attrs"
      :autocomplete="autocomplete"
      @focus="emit('focus')"
      @blur="
        () => {
          checkShowError();
          emit('blur');
        }
      "
    />
    <textarea
      v-if="type === 'textarea'"
      :id="id"
      v-model="fieldValue"
      :name="name"
      :type="showPassword ? 'text' : type"
      :placeholder="placeholder"
      v-bind="$attrs"
      class="w-full py-4 leading-normal"
      :class="{
        'bg-deals-05 placeholder-primary-2-04': filled && !isError,
        'bg-transparent': filled && !isError,
        'bg-red-50': filled && isError,
        'border border-red-700': border && isError,
        'border border-primary-1-15': border && !isError,
        'rounded-md': rounded,
        'has-value': hasValue,
        'pl-10': icon,
        'px-3': !icon,
        'border-b': underline,
        'font-bold': !light,
        'pl-11': isError,
        'with-border': fullBorder,
      }"
      @focus="emit('focus')"
      @blur="
        () => {
          checkShowError();
          emit('blur');
        }
      "
    ></textarea>

    <button
      v-if="type === 'password'"
      type="button"
      class="w-6 h-6 absolute right-5 z-10"
      @click="showPassword = !showPassword"
    >
      <DynamicIcon :name="showPassword ? 'eye' : 'eye-hide'" :width="28" :height="28" />
    </button>

    <svg-icon-error
      v-if="isError"
      class="w-4 h-4 absolute left-3 top-1/2 transform -translate-y-1/2 z-20 fill-current text-danger"
      :class="[type === 'password' ? 'right-14' : 'right-3']"
    />

    <label class="TextInput__label" :for="id">
      <slot name="label" />
      <template v-if="!$slots.label">
        <span>{{ label }}</span>
        <span class="text-primary-2-04">{{ required ? ' *' : '' }}</span>
      </template>
    </label>

    <span v-if="isError" class="TextInput__error">{{ errorMessage || '' }}</span>
    <div v-else-if="$slots.hint" class="TextInput__hint">
      <slot name="hint" />
    </div>

    <slot v-if="$slots.icon" name="icon" />

    <slot name="after" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'text',
  },
  dark: {
    type: Boolean,
    default: false,
  },
  value: {
    type: String,
    default: '',
  },
  autocomplete: {
    type: String,
    default: '',
  },
  filled: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: '',
  },
  border: {
    type: Boolean,
    default: false,
  },
  fullBorder: { type: Boolean, default: false },
  underline: { type: Boolean, default: false },
  light: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  transparent: { type: Boolean, default: true },
  borderRight: { type: Boolean, default: false },
});

const nameRef = toRef(props, 'name');

const { errorMessage, value: fieldValue, meta } = useField<string>(nameRef.value);

const showPassword = ref(false);

const hasValue = computed(() => {
  return fieldValue?.toString().length;
});

const isError = computed(() => !!errorMessage.value?.length && meta.touched);

const emit = defineEmits(['focus', 'blur', 'input']);

/**
 * will be called when user leaves the input field.
 * if the user didn't input any data, then set touched flag
 * to avoid showing error
 */
function checkShowError() {
  if (!meta.dirty) {
    meta.touched = false;
    return;
  }

  if (!meta.valid) {
    meta.touched = true;
  }
}

watch(
  () => meta.valid,
  valid => {
    // To re-evaluate when validity changes to false
    // without having to use the input event to avoid aggressive validation
    if (!valid) {
      checkShowError();
      emit('blur');
    }
  }
);
</script>

<style lang="postcss" scoped>
.TextInput {
  @apply relative flex items-center appearance-none w-full text-primary-1-100;
  input,
  textarea {
    @apply z-10 text-base font-medium;
    position: relative;

    &.with-border {
      @apply border border-primary-2-40 border-opacity-30 rounded-xl;
    }
  }

  &__label {
    @apply absolute block -top-6 bottom-0 inset-x-0 w-full leading-normal text-primary-2-04 text-base capitalize;
    user-select: none;
  }

  &__error,
  &__hint {
    @apply -mt-2 block text-red-700 text-xs absolute left-3 w-full;
    bottom: -20px;
  }

  /* &__hint {
    @apply -mt-2 block text-red-700 text-sm absolute left-3;
    bottom: -5px;
  } */

  input.has-value,
  input:focus,
  textarea.has-value,
  textarea:focus {
    @apply outline-none;
  }
}

input::before {
  content: 'looks good';
  color: green;
}

:deep(.nuxt-icon svg) {
  height: 24px;
  width: 24px;
}
</style>

<i18n>
{
  "en": {
    "optional": "(OPTIONAL)"
  },
  "ar": {
    "optional": "(اختياري)"
  }
}
</i18n>
